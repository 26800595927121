import anime from "animejs";
import { useState, useLayoutEffect, isNumber, useRef, useEffect, MainContext, useContext } from "UIV2";
import { Container, Prefix, LargeNum, SmallNum, Rounder, Suffix } from "./AnimatedShortNumber.styled.js";

export const AnimatedShortNumber = (props) => {
    const { isLoadingAfterDelayX2 } = useContext(MainContext);
    const { pre, suf, num = 0, children, animated = false, table = false, dontShorten } = props || {};
    const [rawNum, setRawNum] = useState(0);
    const [large, setLarge] = useState(0);
    const [small, setSmall] = useState();
    const [rounder, setRounder] = useState();
    const activeNumberSpan = useRef();
    const activeNumberSpanPrinted = useRef();
    const animationRef = useRef();
    const currentNumber = useRef();

    /**
     * Define Const
     */
    const defineConst = () => {
        const currentNum = children || num;
        if (!currentNum || !isNumber(currentNum)) return;
        setRawNum(currentNum);
        if (dontShorten) {
            setLarge(num);
            return;
        }
        const splitCurrentNum = currentNum.toString().split(".");
        const beforeDot = Number(splitCurrentNum[0]);
        const afterDot = Number(Number(splitCurrentNum[1]).toFixed(2));

        if (beforeDot > 1000000000) {
            setLarge(Math.round(beforeDot / 1000000000));
            setRounder("B");
            setSmall();
        } else if (beforeDot > 1000000) {
            setLarge(Math.round(beforeDot / 1000000));
            setRounder("M");
            setSmall();
        } else if (beforeDot > 1000) {
            setLarge(Math.round(beforeDot / 1000));
            setRounder("K");
            setSmall();
        } else {
            setLarge(beforeDot);
            setRounder();
            if (!afterDot) setSmall("00");
            else if (afterDot.toString().length === 1) setSmall(afterDot + "0");
            else setSmall(afterDot);
        }
    };
    useLayoutEffect(() => defineConst(), [num, children]);

    /**
     * Activate number animations
     */
    const animateCurrentNumber = () => {
        if (isLoadingAfterDelayX2) return;
        if (!large || !animated || !activeNumberSpanPrinted.current || !activeNumberSpan.current) return;
        const FPSUnit = large - large / 2;
        const incrementor = 1;

        currentNumber.current = (currentNumber?.current || large / 2) + FPSUnit;
        if (currentNumber.current > large) currentNumber.current = large;

        animationRef.current = anime({
            targets: activeNumberSpan.current,
            textContent: currentNumber.current,
            round: incrementor ? 1 / incrementor : 1 / 5,
            easing: "easeOutQuint",
            duration: 1000,
            update: function (a) {
                const value = a.animations[0].currentValue;
                if (activeNumberSpanPrinted.current) {
                    activeNumberSpanPrinted.current.innerHTML = value?.toLocaleString?.();
                }
            },
        });
    };
    useEffect(() => {
        setTimeout(animateCurrentNumber, 500);
    }, [large, isLoadingAfterDelayX2]);

    /**
     * Return
     */
    return (
        <Container aria-label="ShortNum" title={rawNum.toLocaleString()}>
            {!pre ? null : <Prefix {...{ table }}>{pre}</Prefix>}
            {animated ? (
                <>
                    <LargeNum hide ref={activeNumberSpan}>
                        {large / 2}
                    </LargeNum>
                    <LargeNum {...{ table }} ref={activeNumberSpanPrinted}>
                        {large / 2}
                    </LargeNum>
                </>
            ) : (
                <LargeNum>{large}</LargeNum>
            )}
            {!small || table ? null : <SmallNum>.{small}</SmallNum>}
            {!rounder ? null : <Rounder {...{ table }}>{rounder}</Rounder>}
            {!suf ? null : <Suffix {...{ table }}>{suf}</Suffix>}
        </Container>
    );
};
